<template>
  <div class="container page_book">
    <van-nav-bar
      :title="book['class_name'] + ' - ' + book['book_name']"
      left-arrow
      class="nav_bar"
      @click-left="nav_back"
    />
    <img src="~@/assets/img/practice/bg1.png" class="fix_bg" />
    <div class="unit_list_box">
      <div
        class="unit_list"
        v-for="(item, idx) in unit"
        :key="idx"
        @click="router_unit(item.id)"
      >
        <div class="unit_name_box">
          <van-tag type="danger">{{ item.unit_name }}</van-tag>
          <span class="accuracy">正确率：{{ get_accuracy(item) }}</span>
          <i class="clr"></i>
        </div>
        <div class="intro">{{ item.intro }}</div>
        <div class="progress_box">
          <van-progress
            :percentage="get_progress(item)"
            :stroke-width="4"
            track-color="rgba(255, 244, 229, 1)"
            color="rgba(255, 187, 95, 1)"
            :show-pivot="false"
          />
        </div>
        <p class="clr"></p>
      </div>
    </div>
  </div>
</template>
<script>
import { NavBar, Grid, GridItem, Progress, Tag, Toast } from "vant";
import { get_unit } from "@/api/practice/api";
export default {
  name: "Book",
  data() {
    return {
      data_loaded: false,
      book: {
        book_name: "",
        class_name: "",
        id: 0
      },
      unit: []
    };
  },
  watch: {
    $router: {
      handler: "req_get_unit", //调用方法
      immediate: true //进入立即执行一次
    }
  },
  methods: {
    nav_back() {
      this.$router.push({ path: "/practice?ts=" + Math.random() });
    },
    req_get_unit() {
      this.data_loaded = false;

      let id = this.$store.getters.book_id;
      if (isNaN(id) || id == "") {
        this.nav_back();
        return;
      }

      get_unit({
        id: id
      }).then(res => {
        if (res.code != 200) {
          Toast(res.info);
          return;
        }
        this.data_loaded = true;
        this.unit = res.data.unit;
        this.book = res.data.book;
      });
    },
    router_unit(id) {
      this.$store.commit("set_unit_id", id);
      this.$router.push({ path: "/unit?ts=" + Math.random() });
    },
    get_accuracy(unit) {
      let all_count = unit.wrong_count + unit.right_count;
      if (all_count == 0) {
        return "--";
      }
      return parseInt((unit.right_count / all_count) * 100) + "%";
    },
    get_progress(unit) {
      let all_count = unit.wrong_count + unit.right_count;
      if (all_count == 0) {
        return 0;
      }
      return parseInt((all_count / unit.count) * 100);
    }
  },
  components: {
    [Grid.name]: Grid,
    [NavBar.name]: NavBar,
    [GridItem.name]: GridItem,
    [Progress.name]: Progress,
    [Toast.name]: Toast,
    [Tag.name]: Tag
  }
};
</script>
<style lang="stylus">
.page_book .nav_bar{
    background none
    border-bottom 0
}
.page_book .van-nav-bar__content{
    height: 54px;
}
.page_book .van-nav-bar__title{
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
}
.page_book .van-hairline--bottom::after{
    border none
}
.page_book .van-nav-bar .van-icon{
    color #fff
    font-size 18px
}
</style>

<style lang="stylus" scoped>
.fix_bg{
    width 100%
    position absolute
    top 0
    left 0
    z-index 0
}
.container{
    width 100%
    height 100%
    background rgba(245, 245, 245, 1)
}
.unit_list_box{
    width 90%
    margin 0 auto
    margin-top 30px
    position relative
    z-index 1
}
    .unit_list{
        padding 16px
        width: 342px;
        background: #FFFFFF;
        border-radius: 8px 32px 8px 8px;
        box-sizing border-box
        margin 10px 0
    }
        .unit_name_box{

        }
            .accuracy{
                width: auto;
                height: 18px;
                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                line-height: 18px;
                float right
                position relative
                top 6px
            }
        .intro{
            color rgba(36, 37, 62, 1)
            padding-top 15px
            padding-bottom 8px
            font-size 13px
            padding-left 2px
        }
        .progress_box{
            margin-top 5px
        }
.van-tag{
    padding: 6px 18px;
    border-radius: 50px;
    font-size: 12px;
    font-weight  bold
    background: linear-gradient(90deg, #FF5859 0%, #FF9A5F 100%);
}
</style>
